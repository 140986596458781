
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/camelcase */
import { Component, Vue } from 'vue-property-decorator'
import { State } from 'vuex-class'
import SellerHeader from '@/components/Seller/SellerHeader.vue'
import { getProductInformation } from '@/services/seller.service'
import { Product } from '@/interfaces/product.interface'
import { isoDateToHour, isoDateToSimpleDate } from '@/helpers/date'
import { ErrorMessages } from '@/helpers/constants'

@Component({
  components: { SellerHeader },
})
export default class ProductInfo extends Vue {
  [x: string]: /* eslint-disable @typescript-eslint/no-explicit-any */
  /* eslint-disable @typescript-eslint/camelcase */
  any
  productDisabled = false
  productDisabledMessage: any

  @State(({ userState }) => userState.userInfo) userInfo!: any

  public product?: Product | any = false

  async getProductInfo() {
    const loading = this.$loading.show()
    try {
      const { data } = await getProductInformation(this.$route.params.productId)

      this.product = data

      console.log(this.product);

      this.checkProductIfDisabled(this.product)
      // Tolerance placeholder if null
      if (this.product.tolerance == null || this.product.tolerance == 0) {
        this.product.tolerance = 'Sin tolerancia'
      }
      loading.hide()
    } catch (error) {
      Vue.$toast.error(ErrorMessages.COMMON)
      loading.hide()
    }
  }

  formatDate(isoDate: string) {
    return isoDateToSimpleDate(isoDate)
  }
  formatHour(isoDate: string) {
    return isoDateToHour(isoDate)
  }

  ocupate(total_tickets_active: number | null) {
    return total_tickets_active || 0
  }

  toGenerateQr() {
    this.$router.push({
      name: 'Generar qr',
      params: { productId: this.$route.params.productId },
    })
  }

  checkProductIfDisabled(product: any) {
    if (
      product.total_tickets_active >= product.max_quote ||
      product.sell_simple > 0
    ) {
      this.productDisabled = true
      this.productDisabledMessage =
        'No se puede generar tickets para este producto ya que el mismo no tiene más cupos disponibles'
    } else if (product.expired) {
      this.productDisabled = true
      this.productDisabledMessage =
        'No se puede generar tickets para este producto ya que el mismo ha caducado'
    }
  }

  /**
    protected capture() {
      const captureElement = document.querySelector("#capture");
      this.$html2canvas(captureElement)
        .then((canvas: any) => {
          canvas.style.display = "none";
          document.body.appendChild(canvas);
          return canvas;
        })
        .then((canvas: any) => {
          const image = canvas
            .toDataURL("image/png")
            .replace("image/png", "image/octet-stream");
          const a = document.createElement("a");
          a.setAttribute("download", "my-image.png");
          a.setAttribute("href", image);
          a.click();
          canvas.remove();
        });
    }
  
    **/
  mounted() {
    this.getProductInfo()
  }
}
